import pageQuery from '@gql/queries/pageQuery';
import { storeToRefs } from 'pinia';

export default async function (gqlFragment, collection) {
    const route = useRoute();
    const location = useRequestURL();
    const { host } = location;
    const { currentCenter } = useCenters();
    const { subscribeModalQueryVars } = storeToRefs(useSubscribeModalQueryVarsStore());
    const { locale, localeFromRoute } = useLocale();
    const { updatePageTranslations } = usePageTranslations();
    const { updatePageStatus, updatePageLanguage } = usePageDataStore();
    const nuxtApp = useNuxtApp();

    const {
        public: { allowedDomainOverride },
    } = useRuntimeConfig();

    const query = pageQuery(gqlFragment);
    // Get the collection name from the fragment
    // Remove the 'Fragment' suffix and lowercase the first letter
    collection =
        collection ||
        `${
            gqlFragment.definitions[0].name.value.charAt(0).toLowerCase() +
            gqlFragment.definitions[0].name.value.slice(1).replace('Fragment', '')
        }s`;

    const variables = {
        path: route.path,
        domain: allowedDomainOverride || host,
        center: toValue(currentCenter).slug,
        locale: localeFromRoute.value || locale.value,
        collection,
        token: route.query?.token,
    };

    // SSR Query
    const pageData = reactive({ data: null });
    writeLastQuery(query, variables, 'pageQuery');

    const setData = async (data) => {
        if (data?.page === null) {
            const { redirectRoute, invalidUrlOptions } = await nuxtApp.runWithContext(handleInvalidUrl);

            if (redirectRoute?.path) {
                return navigateTo(redirectRoute, invalidUrlOptions);
            } else {
                const error = { statusCode: 404 };
                showError(error);
                throw createError(error);
            }
        }

        const url = getCurrentUrlWithParamsFromLocation(location, data?.page?.locale || 'en');
        if (url !== location.href && import.meta.server) {
            pageData.data = {};
            return await nuxtApp.runWithContext(() => navigateTo(url, { external: true }));
        }

        const cleanedData = cleanObjectKeys(data);
        updatePageTranslations(cleanedData?.page?.translations);
        pageData.data = cleanedData.page;
        subscribeModalQueryVars.value = { collection, id: data.page?.id };

        let shareImage = 'https://ceipimg.s3.amazonaws.com/static/metadefault-Twittercard-ceip.png';
        if (cleanedData?.page?.contentType === 'centers' && cleanedData?.page?.slug === 'europe') {
            shareImage = 'https://ceipimg.s3.amazonaws.com/static/metadefault-Twittercard-ce.png';
        }
        // if (cleanedData?.page?.seo?.ogImage) {shareImage = cleanedData.page.seo.ogImage}
        // commented out because this is returning a value but it is not a field we can actually use
        if (cleanedData?.page?.hero?.image?.url) {
            shareImage = pageData.data.hero.image.url;
        } else if (cleanedData?.page?.avatar?.url) {
            shareImage = pageData.data.avatar.url;
        } else if (cleanedData?.page?.heroProgramsProjects?.heroImage?.url) {
            shareImage = pageData.data.heroProgramsProjects.heroImage.url;
        } else if (cleanedData?.page?.heroBlog?.heroImage?.url) {
            shareImage = pageData.data.heroBlog.heroImage.url;
        } else if (cleanedData?.page?.video?.url) {
            shareImage = pageData.data.video.url;
        }

        nuxtApp.runWithContext(() => {
            useSeoMeta({
                title: `${cleanedData?.page?.title}`,
                ogTitle: cleanedData?.page?.title,
                description: cleanedData?.page?.seo?.metaDescription,
                ogDescription: cleanedData?.page?.seo?.socialShareDescription,
                ogImage: shareImage,
                twitterTitle: cleanedData?.page?.title,
                twitterDescription: cleanedData?.page?.seo?.socialShareDescription,
                twitterImage: shareImage,
                twitterCard: 'summary_large_image',
                ogUrl: `https://carnegieendowment.org${cleanedData?.page?.path}?lang=${cleanedData?.page?.locale}`,
            });
        });
    };
    const { data } = await useAsyncQuery(query, variables);

    await setData(data?.value);

    updatePageStatus(pageData.data?.status);
    updatePageLanguage(pageData.data?.locale);

    return pageData;
}
