// @ts-nocheck

export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    messages: {
        en: {
            'Carnegie Endowment for International Peace': 'Carnegie Endowment for International Peace',
            'Washington DC, USA': 'Washington DC, USA',
            'California, USA': 'California, USA',
            Singapore: 'Singapore',
            'Beijing, China': 'Beijing, China',
            'Brussels, Belgium': 'Brussels, Belgium',
            'New Delhi, India': 'New Delhi, India',
            'Beirut, Lebanon': 'Beirut, Lebanon',
            'Berlin, Germany': 'Berlin, Germany',
            'Activities in Singapore and Beijing, China': 'Activities in Singapore and Beijing, China',
            Global: 'Global',
            China: 'China',
            Europe: 'Europe',
            India: 'India',
            'Middle East': 'Middle East',
            'Carnegie Europe': 'Carnegie Europe',
            'Carnegie India': 'Carnegie India',
            'Malcolm H. Kerr Carnegie Middle East Center': 'Malcolm H. Kerr Carnegie Middle East Center',
            'Carnegie Russia Eurasia Center': 'Carnegie Russia Eurasia Center',
            'Russia Eurasia': 'Russia Eurasia',
            'Carnegie China': 'Carnegie China',
            'Global Locations': 'Global Locations',
            'Annual Reports': 'Annual Reports',
            emissary: 'emissary',
            privacy: 'privacy',
            subscribe: 'subscribe',
            Phone: 'Phone',
            'for media': 'For Media',
            'government resources': 'Government Resources',
            by: 'by',
            country: 'country',
            countries: 'countries',
            'published by': 'published by',
            'Published on': 'Published on',
            'published on': 'published on',
            'read more': 'read more',
            'read less': 'read less',
            affiliations: 'affiliations',
            and: 'and',
            'areas of expertise': 'areas of expertise',
            education: 'education',
            languages: 'languages',
            english: 'english',
            'featured work': 'featured work',
            'featured collections': 'featured collections',
            Collections: 'Collections',
            program: 'program',
            blog: 'blog',
            project: 'project',
            projects: 'projects',
            results: 'results',
            Results: 'Results',
            'see results': 'see results',
            'table of contents': 'table of contents',
            'about the carnegie endowment': 'about the carnegie endowment',
            'about the center': 'about the center',
            'about us': 'about',
            'about the expert': 'about',
            'more on': 'more on',
            'more on the center': 'more on the center',
            'trending topics and regions': 'trending topics and regions',
            'see all regions': 'see all regions',
            'see all topics': 'see all topics',
            'see the collection': 'see the collection',
            'All work from': 'All work from',
            'who we are': 'who we are',
            commentary: 'commentary',
            book: 'book',
            report: 'report',
            'press release': 'press release',
            'q&a': 'q&a',
            'Q&A': 'Q&A',
            testimony: 'testimony',
            'scholar spotlight': 'scholar spotlight',
            video: 'video',
            collection: 'collection',
            'event collection': 'event collection',
            edition: 'edition',
            paper: 'paper',
            article: 'article',
            'in the media': 'in the media',
            'In the Media': 'In the Media',
            'In The Media': 'In The Media',
            january: 'january',
            february: 'february',
            march: 'march',
            april: 'april',
            may: 'may',
            june: 'june',
            july: 'july',
            august: 'august',
            september: 'september',
            october: 'october',
            november: 'november',
            december: 'december',
            January: 'january',
            February: 'february',
            March: 'march',
            April: 'april',
            May: 'may',
            June: 'june',
            July: 'july',
            August: 'august',
            September: 'september',
            October: 'october',
            November: 'november',
            December: 'december',
            event: 'event',
            episode: 'episode',
            issue: 'issue',
            pdf: 'pdf',
            'latest work': 'latest work',
            filters: 'filters',
            topics: 'topics',
            regions: 'regions',
            Topics: 'Topics',
            Regions: 'Regions',
            types: 'types',
            years: 'years',
            email: 'email',
            editions: 'editions',
            authors: 'authors',
            'additional links': 'additional links',
            'event speakers': 'event speakers',
            podcast: 'podcast',
            'Ep.': 'Ep.',
            'see podcast': 'see podcast',
            'latest from': 'latest from',
            'hosted by': 'Hosted By',
            featuring: 'Featuring',
            'subscribe on': 'Subscribe On',
            'sign up for events': 'Sign up for Events',
            'past events': 'Past Events',
            all: 'All',
            All: 'All',
            AllResearch: 'All',
            'Center Affiliation': 'Center Affiliation',
            research: 'research',
            'the emissary': 'the emissary',
            about: 'about',
            experts: 'experts',
            staff: 'staff',
            donate: 'donate',
            Donate: 'Donate',
            archive: 'archive',
            'this person has been archived': 'this person has been archived',
            'this project has been archived': 'this project has been archived',
            previous: 'previous',
            next: 'next',
            with: 'with',
            more: 'more',
            politika: 'politika',
            diwan: 'diwan',
            events: 'events',
            programs: 'programs',
            series: 'series',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                'this expert is no longer an active Scholar at Carnegie Endowment for International Peace', //needs translation
            blogs: 'blogs',
            videos: 'videos',
            podcasts: 'podcasts',
            'regional centers': 'regional centers',
            'about the program': 'about the program',
            'about the project': 'about the project',
            'About the Project': 'About the Project',
            contact: 'contact',
            'annual reports': 'annual reports',
            careers: 'careers',
            'topics & regions': 'Topics & Regions',
            'Get more news and insights from': 'Get more news and analysis from',
            'More work from': 'More work from',
            close: 'Close',
            'recent articles & papers': 'Recent Articles & Papers',
            'recently published': 'recently published',
            'Recently published': 'recently published',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'Spotify',
            applePodcasts: 'Apple Podcasts',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: 'Amazon Music',
            googlePodcasts: 'Google Podcasts',
            youtubeMusic: 'YouTube Music',
            newsletters: 'newsletters',
            left: 'Left',
            right: 'Right',
            'link copied': 'Link copied',
            'Carnegie Experts': 'Carnegie Experts',
            'programs and projects': 'programs and projects',
            conference: 'conference',
            'register to attend': 'register to attend',
            'add to calendar': 'add to calendar',
            clear: 'clear',
            russian: 'russian',
            german: 'german',
            sourceGetty: 'Source: Getty',
            copyright: '2024 Carnegie Endowment for International Peace. All rights reserved.',
            'learn more': 'Learn More',
            'Arms Control': 'Arms Control',
            'Artificial Intelligence': 'Artificial Intelligence',
            Biotechnology: 'Biotechnology',
            'Civil Society': 'Civil Society',
            'Climate Change': 'Climate Change',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'Democracy',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Domestic Politics',
            EU: 'EU',
            Economy: 'Economy',
            Education: 'Education',
            Energy: 'Energy',
            'Foreign Policy': 'Foreign Policy',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': 'Nuclear Energy',
            'Nuclear Policy': 'Nuclear Policy',
            'Political Economy': 'Political Economy',
            'Political Reform': 'Political Reform',
            Religion: 'Religion',
            Security: 'Security',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'Trade',
            Africa: 'Africa',
            'North Africa': 'North Africa',
            Algeria: 'Algeria',
            Egypt: 'Egypt',
            Libya: 'Libya',
            Mali: 'Mali',
            Morocco: 'Morocco',
            Sudan: 'Sudan',
            Tunisia: 'Tunisia',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: 'Mauritania',
            Americas: 'Americas',
            'North America': 'North America',
            'United States': 'United States',
            Canada: 'Canada',
            California: 'California',
            'Central America': 'entral America',
            Mexico: 'Mexico',
            'South America': 'South America',
            Asia: 'Asia',
            'Central Asia': 'Central Asia',
            Kazakhstan: 'Kazakhstan',
            'Kyrgyz Republic': 'Kyrgyz Republic',
            Tajikistan: 'Tajikistan',
            Turkmenistan: 'Turkmenistan',
            Uzbekistan: 'Uzbekistan',
            Russia: 'Russia',
            'East Asia': 'East Asia',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: 'Japan',
            'North Korea': 'North Korea',
            'South Korea': 'South Korea',
            Taiwan: 'Taiwan',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': 'South Asia',
            Afghanistan: 'Afghanistan',
            Pakistan: 'Pakistan',
            'Southeast Asia': 'Southeast Asia',
            Indonesia: 'Indonesia',
            Myanmar: 'Myanmar',
            Philippines: 'Philippines',
            Vietnam: 'Vietnam',
            Australia: 'Australia',
            'Eastern Europe': 'Eastern Europe',
            Belarus: 'Belarus',
            Germany: 'Germany',
            Ukraine: 'Ukraine',
            Türkiye: 'Türkiye',
            Moldova: 'Moldova',
            'Western Europe': 'Western Europe',
            France: 'France',
            'United Kingdom': 'United Kingdom',
            Bahrain: 'Bahrain',
            Georgia: 'Georgia',
            Iran: 'Iran',
            Iraq: 'Iraq',
            Israel: 'Israel',
            Jordan: 'Jordan',
            Kuwait: 'Kuwait',
            Lebanon: 'Lebanon',
            Palestine: 'Palestine',
            Qatar: 'Qatar',
            'Saudi Arabia': 'Saudi Arabia',
            Syria: 'Syria',
            'United Arab Emirates': 'United Arab Emirates',
            Yemen: 'Yemen',
            'Russia and Eurasia': 'Russia and Eurasia',
            Caucasus: 'Caucasus',
            Armenia: 'Armenia',
            Azerbaijan: 'Azerbaijan',
            support: 'support',
            Newsletters: 'Newsletters',
            ' government resources': ' government resources',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie India, its staff, or its trustees.',
            'You will receive an email confirming your registration.':
                'You will receive an email confirming your registration.',
            'thank you': 'Thank you!',
            Registration: 'Registration',
            Notes: 'Notes',
        },
        ar: {
            'Carnegie Endowment for International Peace': 'مؤسسة كارنيغي للسلام الدولي',
            'Washington DC, USA': 'واشنطن العاصمة، الولايات المتحدة الأميركية',
            'California, USA': 'كاليفورنيا، الولايات المتحدة الأميركية',
            Singapore: 'سنغافورة',
            'Beijing, China': 'بيجينغ، الصين',
            'Brussels, Belgium': 'بروكسل، بلجيكا',
            'New Delhi, India': 'نيو دلهي، الهند',
            'Beirut, Lebanon': 'بيروت، لبنان',
            'Berlin, Germany': 'برلين، ألمانيا',
            'Activities in Singapore and Beijing, China': 'Activities in Singapore and Beijing, China',
            Global: 'عالمي',
            China: 'الصين',
            Europe: 'أوروبا',
            India: 'الهند',
            'Middle East': 'الشرق الأوسط',
            'Carnegie Europe': 'مركز كارنيغي أوروبا',
            'Carnegie India': 'مركز كارنيغي الهند',
            'Malcolm H. Kerr Carnegie Middle East Center': 'مركز مالكوم كير-كارنيغي للشرق الأوسط',
            'Carnegie Russia Eurasia Center': 'مركز كارنيغي روسيا وأوراسيا',
            'Russia Eurasia': 'روسيا أوراسيا',
            'Carnegie China': 'مركز كارنيغي الصين',
            subscribe: 'إشترك',
            Phone: 'هاتف',
            'for media': 'لوسائل الإعلام',
            'government resources': 'Government Resources',
            by: '',
            country: 'البلد',
            countries: 'البلدان',
            'published by': 'نشرت من قبل',
            'published on': 'نشرت في',
            'Published on': 'نشرت في',
            'read more': 'لقراءة المزيد',
            'read less': 'قراءة أقل',
            affiliations: 'الارتباطات',
            and: 'و',
            'areas of expertise': 'مجالات الخبرة',
            education: 'التحصيل العلمي',
            languages: 'اللغات',
            english: 'الإنكليزية',
            'featured work': 'أعمال مختارة',
            'featured collections': 'مجموعات مختارة',
            Collections: 'مجموعات',
            program: 'البرنامج',
            blog: 'المدوّنة',
            project: 'المشروع',
            projects: 'المشاريع',
            Projects: 'المشاريع',
            results: 'النتائج',
            Results: 'النتائج',
            'see results': 'عرض النتائج',
            'table of contents': 'المحتويات',
            'about the carnegie endowment': 'عن المؤسسة',
            'about the center': 'عن المركز',
            'about us': 'عن المركز',
            'about the expert': 'عن الباحث',
            'more on': 'المزيد عن',
            'more on the center': 'المزيد عن المركز',
            'trending topics and regions': 'المواضيع والمناطق المتداولة',
            'see all regions': 'عرض كل المناطق',
            'see all topics': 'عرض كل المواضيع',
            'see the collection': 'عرض المجموعة',
            'All work from': 'المزيد عن',
            'who we are': 'who we are',
            commentary: 'مقال',
            Commentary: 'مقال',
            book: 'كتاب',
            report: 'تقرير',
            'press release': 'بيان صحافي',
            'q&a': 'سؤال وجواب',
            'Q&A': 'سؤال وجواب',
            testimony: 'شهادة',
            'scholar spotlight': 'أبرز مقالات الباحث',
            video: 'فيديو',
            Videos: 'فيديو',
            videos: 'فيديو',
            collection: 'مجموعة',
            'event collection': 'جمع الأحداث',
            edition: 'الإصدار',
            paper: 'دراسة',
            article: 'مقال',
            'in the media': 'في وسائل الإعلام',
            'In the Media': 'في وسائل الإعلام',
            'In The Media': 'في وسائل الإعلام',
            january: 'يناير',
            february: 'فبراير',
            march: 'مارس',
            april: 'أبريل',
            may: 'مايو',
            june: 'يونيو',
            july: 'يوليو',
            august: 'أغسطس',
            september: 'سبتمبر',
            october: 'أكتوبر',
            november: 'نوفمبر',
            december: 'ديسمبر',
            January: 'يناير',
            February: 'فبراير',
            March: 'مارس',
            April: 'أبريل',
            May: 'مايو',
            June: 'يونيو',
            July: 'يوليو',
            August: 'أغسطس',
            September: 'سبتمبر',
            October: 'أكتوبر',
            November: 'نوفمبر',
            December: 'ديسمبر',
            event: 'نشاط',
            episode: 'حلقة',
            'Podcast Episodes': 'حلقة',
            issue: 'قضية',
            pdf: 'ملف',
            'latest work': 'الأحدث',
            filters: 'فرز الخيارات',
            topics: 'المواضيع',
            regions: 'المناطق',
            Topics: 'المواضيع',
            Regions: 'المناطق',
            types: 'الأنواع',
            years: 'السنوات',
            email: 'البريد الإلكتروني',
            editions: 'الطبعات',
            authors: 'المؤلفون',
            'additional links': 'روابط إضافية',
            'event speakers': 'المتحدثّون',
            'Ep.': 'حلقة',
            'see podcast': 'متابعة البودكاست',
            'latest from': 'الأحدث من',
            'hosted by': 'باستضافة',
            featuring: 'عرض',
            'subscribe on': 'للاشتراك في',
            'sign up for events': 'اشترك في الأحداث',
            'past events': 'الأنشطة السابقة',
            all: 'الكل',
            All: 'الكل',
            AllResearch: 'الكل',
            'Center Affiliation': 'ارتباطات المركز',
            research: 'الأبحاث',
            Research: 'الأبحاث',
            'the emissary': 'the emissary',
            about: 'عن',
            experts: 'الباحثون',
            staff: 'فريق العمل',
            donate: 'للتبرّع',
            Donate: 'للتبرّع',
            privacy: 'بيان الخصوصية',
            archive: 'الأرشيف',
            'this person has been archived': 'تم أرشفة هذا الشخص',
            'this project has been archived': 'تمّت أرشفة هذا المشروع',
            previous: 'السابق',
            Prev: 'السابق',
            next: 'التالي',
            Next: 'التالي',
            with: 'مع',
            more: 'أكثر',
            politika: 'politika',
            arabic: 'العربية',
            diwan: 'ديوان',
            events: 'الأنشطة',
            Events: 'الأنشطة',
            programs: 'البرامج',
            series: 'سلسلة',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                'لم يعد هذا الخبير باحثًا نشطًا في Carnegie Endowment للسلام الدولي',
            blogs: 'المدوّنات',
            podcasts: 'البودكاست',
            Podcasts: 'البودكاست',
            'regional centers': 'المراكز الإقليمية',
            'about the program': 'عن البرنامج',
            'about the project': 'عن المشروع',
            'About the Project': 'عن المشروع',
            contact: 'اتصلوا بنا',
            'annual reports': 'annual reports',
            careers: 'فرص العمل',
            'topics & regions': 'المواضيع والمناطق',
            'Get more news and insights from': 'لتلقّي المزيد من الأخبار والتحليلات الثاقبة من',
            'middle east center': 'مركز مالكوم كير-كارنيغي للشرق الأوسط',
            'More work from': 'جميع الأبحاث من',
            'more work from': 'جميع الأبحاث من',
            close: 'إغلاق',
            'recent articles & papers': 'آخر المقالات والدراسات',
            'recently published': 'ما نُشر حديثًا',
            'Recently published': 'ما نُشر حديثًا',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'سبوتيفاي',
            applePodcasts: 'آبل بودكاست',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: 'أمازون ميوزك',
            googlePodcasts: 'غوغل بودكاست',
            youtubeMusic: 'YouTube Music',
            newsletters: 'النشرات',
            left: 'يسار',
            right: 'يمين',
            'link copied': 'رابط نسخ',
            'Carnegie Experts': 'باحثون كارنيغي',
            'programs and projects': 'البرامج والمشاريع',
            conference: 'مؤتمر',
            'register to attend': 'تسجيل الحضور',
            'add to calendar': 'إضافة إلى التقويم',
            clear: 'امسح',
            sourceGetty: 'المصدر: Getty',
            copyright: '2024 جميع الحقوق محفوظة',
            'learn more': 'للمزيد من المعلومات',
            'Arms Control': 'مراقبة التسلّح',
            'Artificial Intelligence': 'Artificial Intelligence',
            Biotechnology: 'Biotechnology',
            'Civil Society': 'المجتمع المدني',
            'Climate Change': 'المناخ',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'الديمقراطية',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Domestic Politics',
            EU: 'EU',
            Economy: 'الاقتصاد',
            Education: 'التحصيل العلمي',
            Energy: 'الطاقة',
            'Foreign Policy': 'السياسة الخارجية',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': 'الطاقة النووية',
            'Nuclear Policy': 'السياسة النووية',
            'Political Economy': 'Political Economy',
            'Political Reform': 'الإصلاح السياسي',
            Religion: 'Religion',
            Security: 'الأمن',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'التجارة',
            Africa: 'أفريقيا',
            'North Africa': 'شمال أفريقيا',
            Algeria: 'الجزائر',
            Egypt: 'مصر',
            Libya: 'ليبيا',
            Mali: 'Mali',
            Morocco: 'المغرب',
            Sudan: 'السودان',
            Tunisia: 'تونس',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: 'موريتانيا',
            Americas: 'Americas',
            'North America': 'أميركا الشمالية',
            'United States': 'United States',
            Canada: 'كندا',
            California: 'California',
            'Central America': 'Central America',
            Mexico: 'المكسيك',
            'South America': 'South America',
            Asia: 'آسيا',
            'Central Asia': 'آسيا الوسطى',
            Kazakhstan: 'كازاخستان',
            'Kyrgyz Republic': 'جمهورية قيرغزستان',
            Tajikistan: 'طاجيكيستان',
            Turkmenistan: 'تركمنستان',
            Uzbekistan: 'أوزبكستان',
            Russia: 'روسيا',
            'East Asia': 'شرق آسيا',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: 'اليابان',
            'North Korea': 'كوريا الشمالية',
            'South Korea': 'كوريا الجنوبية',
            Taiwan: 'تايوان',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': 'جنوب آسيا',
            Afghanistan: 'أفغانستان',
            Pakistan: 'باكستان',
            'Southeast Asia': 'جنوب شرق آسيا',
            Indonesia: 'أندونيسيا',
            Myanmar: 'بورما',
            Philippines: 'الفيليبين',
            Vietnam: 'فييتنا',
            Australia: 'أستراليا',
            'Eastern Europe': 'أوروبا الشرقية',
            Belarus: 'روسيا البيضاء',
            Germany: 'ألمانيا',
            Ukraine: 'أوكرانيا',
            Türkiye: 'تركيا',
            Moldova: 'مولدوفا',
            'Western Europe': 'أوروبا الغربية',
            France: 'فرنسا',
            'United Kingdom': 'المملكة المتحدة',
            Bahrain: 'البحرين',
            Georgia: 'جورجيا',
            Iran: 'إيران',
            Iraq: 'العراق',
            Israel: 'إسرائيل',
            Jordan: 'الأردن',
            Kuwait: 'الكويت',
            Lebanon: 'لبنان',
            Palestine: 'فلسطين',
            Qatar: 'قطر',
            'Saudi Arabia': 'المملكة العربية السعودية',
            Syria: 'سوريا',
            'United Arab Emirates': 'الإمارات العربية المتحدة',
            Yemen: 'اليمن',
            'Russia and Eurasia': 'روسيا وأوراسيا',
            Caucasus: 'القوقاز',
            Armenia: 'أرمينيا',
            Azerbaijan: 'أذربيجان',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'لا تتّخذ مؤسسة كارنيغي مواقف مؤسّسية بشأن قضايا السياسة العامة؛ تعبّر وجهات النظر المذكورة في هذه الدراسة عن آراء كاتبها ولا تعكس بالضرورة وجهات نظر المؤسسة، أو فريق عملها، أو مجلس الأمناء فيها.',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'You will receive an email confirming your registration.':
                'ستصلك رسالة إلى بريدك الإلكتروني لتأكيد تسجيل حضورك.',
            'thank you': 'شكرًا',
            Registration: 'تسجيل الحضور',
            Notes: 'هوامش',
        },
        zh: {
            'Carnegie Endowment for International Peace': '卡内基国际和平研究院',
            'Washington DC, USA': '美国华盛顿特区',
            'California, USA': '美国加利福尼亚州',
            Singapore: '新加坡',
            'Beijing, China': '中国北京',
            'Brussels, Belgium': '比利时布鲁塞尔',
            'New Delhi, India': '印度新德里',
            'Beirut, Lebanon': '黎巴嫩贝鲁特',
            'Berlin, Germany': '德国柏林',
            'Activities in Singapore and Beijing, China': '在新加坡和中国北京的活动',
            Global: '全球的',
            China: '中文中心',
            Europe: '欧洲',
            India: '印度',
            'Middle East': '中东',
            'Russia Eurasia': '俄罗斯欧亚大陆',
            'Carnegie China': '卡内基中国',
            'Carnegie Europe': '卡内基欧洲',
            'Carnegie India': '卡内基印度',
            'Malcolm H. Kerr Carnegie Middle East Center': '马尔科姆·H·克尔·卡内基中东中心',
            'Carnegie Russia Eurasia Center': '卡内基俄罗斯欧亚中心',
            subscribe: '订阅',
            Phone: '电话',
            'for media': '媒体',
            'government resources': '政府',
            by: '作者',
            privacy: '隐私声明',
            country: '国家',
            countries: '国家',
            'published by': '由出版',
            'Published on': '发表于',
            'published on': '发表于',
            'read more': '阅读更多',
            'read less': '少阅读',
            affiliations: '从属关系',
            and: '和',
            'areas of expertise': '专业领域',
            education: '教育',
            languages: '语言',
            english: '英语',
            'featured work': '特色工作',
            'featured collections': '特色菌落',
            Collections: 'Collections',
            program: '项目',
            blog: '博客',
            project: '课题',
            projects: '课题',
            Projects: '课题',
            results: '结果',
            Results: '结果',
            'see results': '查看结果',
            'table of contents': '目录',
            'about the carnegie endowment': '关于捐赠',
            'about the center': '关于中心',
            'about us': '关于',
            'about the expert': '关于',
            'more on': '更多关于',
            'more on the center': '更多关于',
            'trending topics and regions': '趋势主题和地区',
            'see all regions': '查看所有地区',
            'see all topics': '查看所有主题',
            'see the collection': '参阅合集',
            'All work from': '更多关于',
            'who we are': 'who we are',
            commentary: '评论',
            Commentary: '评论',
            book: '书',
            report: '报告',
            'press release': '新闻稿',
            'q&a': '问答',
            'Q&A': '问答',
            testimony: '证词',
            'scholar spotlight': '学者聚焦',
            video: '视频',
            Videos: '视频',
            videos: '视频',
            collection: '合集',
            'event collection': '会议合集',
            edition: '版本',
            paper: '论文',
            article: '文章',
            'in the media': '在媒体上',
            'In the Media': '在媒体上',
            january: '一月',
            february: '二月',
            march: '三月',
            april: '四月',
            may: '五月',
            june: '六月',
            july: '七月',
            august: '八月',
            september: '九月',
            october: '十月',
            november: '十一月',
            december: '十二月',
            January: '一月',
            February: '二月',
            March: '三月',
            April: '四月',
            May: '五月',
            June: '六月',
            July: '七月',
            August: '八月',
            September: '九月',
            October: '十月',
            November: '十一月',
            December: '十二月',
            event: '会议',
            episode: '单集',
            'Podcast Episodes': '单集',
            issue: '议题',
            pdf: 'pdf',
            'latest work': '最新分析',
            filters: '过滤器',
            topics: '主题',
            regions: '地区',
            Topics: '主题',
            Regions: '地区',
            types: '类型',
            years: '年',
            email: '电子邮件',
            editions: '版本',
            authors: '作者',
            'additional links': '其他链接',
            'event speakers': '活动演讲者',
            'Ep.': '插曲',
            'see podcast': '请参阅播客',
            'latest from': '最新的',
            'hosted by': '由主办',
            featuring: '特色',
            'subscribe on': '订阅',
            'sign up for events': '注册活动',
            'past events': '过去的事',
            all: '全部',
            All: '全部',
            AllResearch: '全部',
            'Center Affiliation': '中心隶属关系',
            research: '研究',
            Research: '研究',
            about: '关于',
            experts: '专家',
            staff: '职员',
            donate: '捐赠',
            Donate: '捐赠',
            archive: '档案',
            'this person has been archived': '此人已存档',
            'this project has been archived': '该项目已存档。',
            previous: '以前的',
            Prev: '以前的',
            next: '下一个',
            Next: '下一个',
            with: '和',
            more: '更多',
            politika: 'politika',
            diwan: 'diwan',
            events: '会议',
            Events: '会议',
            programs: '项目',
            series: '系列',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                '该专家不再是卡内基国际和平研究院的在任学者',
            blogs: '博客',
            podcasts: '播客',
            Podcasts: '播客',
            'regional centers': '区域中心',
            'about the program': '关于项目',
            'about the project': '关于该项目',
            'About the Project': '关于该项目',
            contact: '联系',
            'annual reports': 'annual reports',
            careers: '职业发展',
            'topics & regions': '主题和地区',
            'Get more news and insights from': '获取最新资讯和见解来自卡内基中国',
            'china center': '中国中心',
            'More work from': '更多',
            close: '关闭',
            'recent articles & papers': '近期文章和论文',
            'recently published': '近期出版物',
            'Recently published': '近期出版物',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'Spotify',
            applePodcasts: '苹果播客',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: '亚马逊音乐',
            googlePodcasts: 'Google播客',
            youtubeMusic: 'YouTube Music',
            left: '左边',
            right: '右边',
            'link copied': '链接复制',
            'Carnegie Experts': '卡内基专家',
            'programs and projects': '项目和课题',
            conference: '大会',
            'register to attend': '注册参加',
            'add to calendar': '添加到日历',
            clear: '清除',
            sourceGetty: '源文件: Getty',
            copyright: '2024 版权所有，侵权必究',
            'learn more': '了解更多',
            'Arms Control': '军控',
            'Artificial Intelligence': 'Artificial Intelligence',
            Biotechnology: 'Biotechnology',
            'Civil Society': '公民社会',
            'Climate Change': 'Climate Change',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'Democracy',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Domestic Politics',
            EU: 'EU',
            Economy: '经济',
            Education: '教育',
            Energy: '能源',
            'Foreign Policy': '外交政策',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': '核能源',
            'Nuclear Policy': '核政策',
            'Political Economy': 'Political Economy',
            'Political Reform': 'Politi政治改革',
            Religion: 'Religion',
            Security: '安全',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'Trade',
            Africa: 'Africa',
            'North Africa': '北非',
            Algeria: '阿尔及利亚',
            Egypt: '埃及',
            Libya: '利比亚',
            Mali: '马里',
            Morocco: '摩洛哥',
            Sudan: '苏丹',
            Tunisia: '突尼斯',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: '毛里塔尼亚',
            Americas: '美洲',
            'North America': '北美',
            'United States': '美国',
            Canada: '加拿大',
            California: 'California',
            'Central America': 'Central America',
            Mexico: '墨西哥',
            'South America': 'South America',
            Asia: '亚洲',
            'Central Asia': '中亚',
            Kazakhstan: '哈萨克斯坦',
            'Kyrgyz Republic': '吉尔吉斯斯坦',
            Tajikistan: '塔吉克斯坦',
            Turkmenistan: '土库曼',
            Uzbekistan: '乌兹别克斯坦',
            Russia: '俄罗斯',
            'East Asia': '突尼斯',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: '日本',
            'North Korea': '北韩, 北朝鲜',
            'South Korea': '韩国, 光州',
            Taiwan: '台湾',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': '南亚',
            Afghanistan: '阿富汗',
            Pakistan: '巴基斯坦',
            'Southeast Asia': '东南亚',
            Indonesia: '印度尼西亚, 印尼',
            Myanmar: '缅甸',
            Philippines: '菲律宾',
            Vietnam: '越南',
            Australia: '澳大利亚',
            'Eastern Europe': '东欧',
            Belarus: '白俄罗斯',
            Germany: '德国',
            Ukraine: '乌克兰',
            Türkiye: '土耳其',
            Moldova: '摩尔多瓦',
            'Western Europe': '西欧',
            France: '法国',
            'United Kingdom': '英国',
            Bahrain: '巴林',
            Georgia: '格鲁吉亚',
            Iran: '伊朗',
            Iraq: '伊拉克',
            Israel: '以色列',
            Jordan: '约旦',
            Kuwait: '科威特',
            Lebanon: '黎巴嫩',
            Palestine: '巴勒斯坦',
            Qatar: '卡塔尔',
            'Saudi Arabia': '沙特阿拉伯',
            Syria: '叙利亚',
            'United Arab Emirates': '阿拉伯联合酋长国',
            Yemen: '也门',
            'Russia and Eurasia': '俄罗斯与东欧',
            Caucasus: '高加索',
            Armenia: '亚美尼亚',
            Azerbaijan: '阿塞拜疆',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                '卡内基国际和平研究院不在公共政策问题上发表机构立场。此处所阐述的观点仅代表作者个人的立场，并不一定反映卡内基及其工作人员和理事的观点。',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'You will receive an email confirming your registration.': '您将收到一封确认注册成功的电子邮件。',
            'thank you': '谢谢',
            Registration: '注册',
            Notes: '注释',
        },
        de: {
            'Carnegie Endowment for International Peace': 'Carnegie Stiftung für internationalen Frieden',
            'Washington DC, USA': 'Washington DC, USA',
            'California, USA': 'Kalifornien, USA',
            Singapore: 'Singapur',
            'Beijing, China': 'Beijing, China',
            'Brussels, Belgium': 'Brüssel, Belgien',
            'New Delhi, India': 'Neu Delhi, Indien',
            'Beirut, Lebanon': 'Beiruit, Libanon',
            'Berlin, Germany': 'Berlin, Deutschland',
            'Activities in Singapore and Beijing, China': 'Activities in Singapore and Beijing, China',
            Global: 'Global',
            China: 'China',
            Europe: 'Europa',
            India: 'Indien',
            'Middle East': 'Naher Osten',
            'Carnegie Europe': 'Carnegie Europa',
            'Carnegie India': 'Carnegie Indien',
            'Malcolm H. Kerr Carnegie Middle East Center': 'Malcolm H. Kerr Carnegie Middle East Center',
            'Carnegie Russia Eurasia Center': 'Carnegie Russland Eurasien Center',
            'Russia Eurasia': 'Russland Eurasien',
            'Carnegie China': 'Carnegie China',
            subscribe: 'abonnieren',
            Phone: 'Phone',
            'for media': 'For Media',
            'government resources': 'Government Resources',
            by: 'von',
            privacy: 'Privacy',
            country: 'land',
            countries: 'länder',
            'published by': 'Herausgegeben von',
            'Published on': 'Veröffentlicht auf',
            'published on': 'Veröffentlicht auf',
            'read more': 'weiterlesen',
            'read less': 'lese weniger',
            affiliations: 'Zugehörigkeit',
            and: 'Und',
            'areas of expertise': 'Fachgebiete',
            education: 'Ausbildung',
            languages: 'Sprachen',
            english: 'englisch',
            'featured work': 'Ausgewählte Arbeit',
            'featured collections': 'Ausgewählte Colotionen',
            Collections: 'Colotionen',
            program: 'Programm',
            blog: 'Blog',
            project: 'Projekt',
            projects: 'projects',
            results: 'Ergebnisse',
            Results: 'Ergebnisse',
            'see results': 'Siehe Ergebnisse',
            'table of contents': 'Inhaltsverzeichnis',
            'about the carnegie endowment': 'Über die Stiftung',
            'about the center': 'Über das Zentrum',
            'about us': 'um',
            'about the expert': 'um',
            'more on': 'Mehr auf',
            'trending topics and regions': 'Trendthemen und Regionen',
            'see all regions': 'Siehe alle Regionen',
            'see all topics': 'Sehen Sie alle Themen',
            'see the collection': 'Siehe die Sammlung',
            commentary: 'Kommentar',
            book: 'Buch',
            report: 'Bericht',
            'press release': 'Pressemitteilung',
            'q&a': 'Fragen und a',
            'Q&A': 'Fragen und a',
            testimony: 'Zeugnis',
            'scholar spotlight': 'Scholarrampenlicht',
            video: 'video',
            collection: 'Sammlung',
            'event collection': 'Eventsammlung',
            edition: 'Auflage',
            paper: 'Papier',
            article: 'Artikel',
            'in the media': 'In den Medien',
            'In the Media': 'In den Medien',
            january: 'Januar',
            february: 'Februar',
            march: 'Marsch',
            april: 'April',
            may: 'Mai',
            june: 'Juni',
            july: 'Juli',
            august: 'august',
            september: 'september',
            october: 'Oktober',
            november: 'november',
            december: 'Dezember',
            event: 'Ereignis',
            episode: 'Folge',
            issue: 'Ausgabe',
            pdf: 'pdf',
            'latest work': 'Актуальное',
            filters: 'Filter',
            topics: 'Themen',
            regions: 'Regionen',
            Topics: 'Themen',
            Regions: 'Regionen',
            types: 'Typen',
            years: 'Jahre',
            email: 'Email',
            editions: 'Ausgaben',
            authors: 'Autoren',
            'additional links': 'Zusätzliche Links',
            'event speakers': 'Veranstaltungsredner',
            'Ep.': 'Folge',
            'see podcast': 'Siehe Podcast',
            'latest from': 'Neulich von',
            'hosted by': 'Veranstaltet von',
            featuring: 'Mit',
            'subscribe on': 'Abonnieren',
            'sign up for events': 'Melden Sie sich für Ereignisse an',
            'past events': 'Vergangene Ereignisse',
            all: 'Alle',
            All: 'Alle',
            AllResearch: 'Alle',
            'Center Affiliation': 'Zentrumzugehörigkeit',
            research: 'forschung',
            'the emissary': 'the emissary',
            about: 'um',
            experts: 'experten',
            staff: 'staff',
            donate: 'Spenden',
            Donate: 'Spenden',
            archive: 'Archiv',
            'this person has been archived': 'Diese Person wurde archiviert',
            'this project has been archived': 'Dieses Projekt wurde archiviert.',
            previous: 'Vorherige',
            Prev: 'Vorherige',
            next: 'Nächste',
            Next: 'Nächste',
            with: 'mit',
            more: 'mehr',
            politika: 'politika',
            diwan: 'diwan',
            events: 'veranstaltungen',
            programs: 'programme',
            series: 'Serie',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                'Dieser Experte ist kein aktiver Gelehrter mehr bei Carnegie Endowment for International Peace',
            blogs: 'blogs',
            podcasts: 'podcasts',
            'regional centers': 'regionalzentren',
            'about the program': 'Über das Programm',
            'about the project': 'Über das Projekt',
            contact: 'kontakt',
            'annual reports': 'annual reports',
            careers: 'Karriere',
            'topics & regions': 'Themen und Regionen',
            'Get more news and insights from': 'Holen Sie sich mehr Neuigkeiten und Erkenntnisse von',
            'More work from': 'Mehr Arbeit von',
            'russia eurasia center': 'Russland Eurasia Center',
            close: 'Schließen',
            'recent articles & papers': 'Neuere Artikel und Papiere',
            'recently published': 'Kürzlich veröffentlicht',
            'Recently published': 'Kürzlich veröffentlicht',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'Spotify',
            applePodcasts: 'Apple Podcasts',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: 'Amazon -Musik',
            googlePodcasts: 'Google Podcasts',
            youtubeMusic: 'YouTube Music',
            newsletters: 'newsletters',
            left: 'Links',
            right: 'Rechts',
            'link copied': 'Link kopiert',
            'Carnegie Experts': 'Carnegie -Experten',
            'programs and projects': 'Programme und Projekte',
            conference: 'Konferenz',
            'register to attend': 'Registrieren Sie sich, um daran teilzunehmen',
            'add to calendar': 'Zum Kalender hinzufügen',
            clear: 'klar',
            sourceGetty: 'Source: Getty',
            copyright: '2024 Carnegie Endowment for International Peace. All rights reserved.',
            'learn more': 'Learn More',
            'Arms Control': 'Arms Control',
            'Artificial Intelligence': 'Artificial Intelligence',
            Biotechnology: 'Biotechnology',
            'Civil Society': 'Civil Society',
            'Climate Change': 'Climate Change',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'Democracy',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Domestic Politics',
            EU: 'EU',
            Economy: 'Economy',
            Education: 'Education',
            Energy: 'Energy',
            'Foreign Policy': 'Foreign Policy',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': 'Nuclear Energy',
            'Nuclear Policy': 'Nuclear Policy',
            'Political Economy': 'Political Economy',
            'Political Reform': 'Political Reform',
            Religion: 'Religion',
            Security: 'Security',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'Trade',
            Africa: 'Africa',
            'North Africa': 'North Africa',
            Algeria: 'Algeria',
            Egypt: 'Egypt',
            Libya: 'Libya',
            Mali: 'Mali',
            Morocco: 'Morocco',
            Sudan: 'Sudan',
            Tunisia: 'Tunisia',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: 'Mauritania',
            Americas: 'Americas',
            'North America': 'North America',
            'United States': 'United States',
            Canada: 'Canada',
            California: 'California',
            'Central America': 'entral America',
            Mexico: 'Mexico',
            'South America': 'South America',
            Asia: 'Asia',
            'Central Asia': 'Central Asia',
            Kazakhstan: 'Kazakhstan',
            'Kyrgyz Republic': 'Kyrgyz Republic',
            Tajikistan: 'Tajikistan',
            Turkmenistan: 'Turkmenistan',
            Uzbekistan: 'Uzbekistan',
            Russia: 'Russia',
            'East Asia': 'East Asia',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: 'Japan',
            'North Korea': 'North Korea',
            'South Korea': 'South Korea',
            Taiwan: 'Taiwan',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': 'South Asia',
            Afghanistan: 'Afghanistan',
            Pakistan: 'Pakistan',
            'Southeast Asia': 'Southeast Asia',
            Indonesia: 'Indonesia',
            Myanmar: 'Myanmar',
            Philippines: 'Philippines',
            Vietnam: 'Vietnam',
            Australia: 'Australia',
            'Eastern Europe': 'Eastern Europe',
            Belarus: 'Belarus',
            Germany: 'Germany',
            Ukraine: 'Ukraine',
            Türkiye: 'Türkiye',
            Moldova: 'Moldova',
            'Western Europe': 'Western Europe',
            France: 'France',
            'United Kingdom': 'United Kingdom',
            Bahrain: 'Bahrain',
            Georgia: 'Georgia',
            Iran: 'Iran',
            Iraq: 'Iraq',
            Israel: 'Israel',
            Jordan: 'Jordan',
            Kuwait: 'Kuwait',
            Lebanon: 'Lebanon',
            Palestine: 'Palestine',
            Qatar: 'Qatar',
            'Saudi Arabia': 'Saudi Arabia',
            Syria: 'Syria',
            'United Arab Emirates': 'United Arab Emirates',
            Yemen: 'Yemen',
            'Russia and Eurasia': 'Russia and Eurasia',
            Caucasus: 'Caucasus',
            Armenia: 'Armenia',
            Azerbaijan: 'Azerbaijan',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'You will receive an email confirming your registration.':
                'You will receive an email confirming your registration.',
            'thank you': 'Thank you!',
            Registration: 'Registration',
            Notes: 'Notes',
        },
        ru: {
            'Carnegie Endowment for International Peace': 'Фонд Карнеги за международный мир',
            'Washington DC, USA': 'Вашингтон, округ Колумбия, США',
            'California, USA': 'Калифорния, США',
            Singapore: 'Сингапур',
            'Beijing, China': 'Beijing, China',
            'Brussels, Belgium': 'Брюссель, Бельгия',
            'New Delhi, India': 'Нью-Дели, Индия',
            'Beirut, Lebanon': 'Бейрут, Ливан',
            'Berlin, Germany': 'Берлин, Германия',
            'Activities in Singapore and Beijing, China': 'Сингапур и Пекин',
            Global: 'Глобальный',
            China: 'Китай',
            Europe: 'Европа',
            India: 'Индия',
            'Middle East': 'Ближний Восток',
            'Carnegie Europe': 'Carnegie Europe',
            'Carnegie India': 'Карнеги Индия',
            'Malcolm H. Kerr Carnegie Middle East Center': 'Карнеги Ближний Восток',
            'Carnegie Russia Eurasia Center': 'Берлинский центр Карнеги',
            'Russia Eurasia': 'Россия и Евразия',
            'Carnegie China': 'Карнеги Китай',
            'Russia Eurasia Center': 'Берлинский центр Карнеги',
            subscribe: 'Подписаться',
            Phone: 'Тел.',
            'for media': 'Для СМИ',
            'government resources': 'Government Resources',
            by: '',
            privacy: 'Конфиденциальность',
            country: 'country',
            countries: 'countries',
            'published by': 'Автор',
            'Published on': '',
            'published on': '',
            'read more': 'Подробнее',
            'read less': 'свернуть',
            affiliations: 'Аффилиации',
            and: 'И',
            'areas of expertise': 'Ключевые темы / Сфера экспертизы',
            education: 'Образование',
            languages: 'Языки',
            english: 'english',
            'featured work': 'Избранное',
            'featured collections': 'Избранные коллекции',
            Collections: 'коллекции',
            program: 'Программа',
            blog: '',
            project: 'Проект',
            projects: 'Проекты',
            Projects: 'Проекты',
            results: 'Результаты',
            Results: 'Результаты',
            'see results': 'Результаты поиска',
            'table of contents': 'Оглавление',
            'about the carnegie endowment': 'О Фонде',
            'about the center': 'О центре',
            'about us': 'О нас',
            'about the expert': 'Биография',
            'more on': 'Еще',
            'more on the center': 'Больше о Центре',
            'trending topics and regions': 'Актуальные темы и регионы',
            'see all regions': 'Все регионы',
            'see all topics': 'Все темы',
            'see the collection': 'see the collection',
            'All work from': 'Еще',
            'who we are': 'who we are',
            commentary: 'Комментарий',
            Commentary: 'Комментарий',
            book: 'Книга',
            report: 'Доклад',
            'press release': 'Пресс-релиз',
            'q&a': 'q&a',
            'Q&A': 'q&a',
            testimony: 'Публичные слушания',
            'scholar spotlight': 'Приветственная статья',
            video: 'Видео',
            Videos: 'Видео',
            videos: 'Видео',
            collection: 'Коллекция',
            'event collection': 'Мероприятия',
            edition: 'Издание',
            paper: 'Брошюра',
            article: 'Статья',
            'in the media': 'В прессе',
            'In the Media': 'В прессе',
            'In The Media': 'В прессе',
            january: 'январь',
            february: 'февраль',
            march: 'март',
            april: 'апрель',
            may: 'май',
            june: 'июнь',
            july: 'июль',
            august: 'август',
            september: 'сентябрь',
            october: 'октябрь',
            november: 'ноябрь',
            december: 'декабрь',
            January: 'январь',
            February: 'февраль',
            March: 'март',
            April: 'апрель',
            May: 'май',
            June: 'июнь',
            July: 'июль',
            August: 'август',
            September: 'сентябрь',
            October: 'октябрь',
            November: 'ноябрь',
            December: 'декабрь',
            event: 'Мероприятие',
            Event: 'Мероприятие',
            episode: 'Эпизод подкаста',
            'Podcast Episodes': 'Эпизод подкаста',
            issue: 'Выпуск',
            pdf: 'pdf',
            'latest work': 'Недавнее',
            filters: 'Фильтры',
            topics: 'Темы',
            regions: 'Регионы',
            Topics: 'Темы',
            Regions: 'Регионы',
            types: 'Типы',
            years: 'Годы',
            email: 'Email',
            editions: 'Издания',
            authors: 'Авторы',
            'additional links': 'Дополнительные ссылки',
            'event speakers': 'Спикеры',
            'Ep.': 'Ep.',
            'see podcast': 'Слушать',
            'latest from': 'Недавние публикации',
            'hosted by': 'ВЕДУЩИЙ:',
            featuring: 'ГОСТИ',
            'subscribe on': 'Подписаться',
            'sign up for events': 'Подписаться на анонсы',
            'past events': 'Проведенные мероприятия',
            'Past Events': 'Проведенные мероприятия',
            all: 'Все',
            All: 'Все',
            AllResearch: 'Все публикации',
            'Center Affiliation': 'Центр:',
            research: 'Исследования',
            Research: 'Исследования',
            politika: 'Carnegie Politika',
            about: 'О нас',
            experts: 'эксперты',
            staff: 'Сотрудники',
            events: 'Мероприятия',
            Events: 'Мероприятия',
            donate: 'Пожертвовать',
            Donate: 'Пожертвовать',
            archive: 'Архив',
            'this person has been archived': 'Информация находится в архиве',
            'this project has been archived': 'Проект находится в архиве.',
            previous: 'Назад',
            Prev: 'Назад',
            next: 'Далее',
            Next: 'Далее',
            with: 'with',
            more: 'Еще',
            programs: 'Программы',
            series: 'Серия',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                'Эксперт больше не работает в Фонде Карнеги за международный мир',
            podcasts: 'Подкасты',
            Podcasts: 'Подкасты',
            'regional centers': 'Центры',
            'about the program': 'О программе',
            'about the project': 'О проекте',
            'About the Project': 'О проекте',
            contact: 'Контакты',
            careers: 'Вакансии',
            'topics & regions': 'Темы и регионы',
            'Get more news and insights from': 'Подпишитесь на рассылку',
            'russia eurasia center': 'Россия Евразия Центр',
            'More work from': 'Еще',
            'more work from': 'Больше от',
            close: 'Закрыть',
            russian: 'русский',
            Ukraine: 'Украина',
            'Central Asia': 'Средняя Азия',
            russia: 'россия',
            caucasus: 'Россия и Кавказ',
            'central asia': 'Средняя Азия',
            china: 'Китай',
            ukraine: 'Украина',
            'united states': 'Соединенные Штаты Америки',
            'domestic politics': 'Внутренняя политика России',
            economy: 'Экономика',
            'energy policy': 'Энергетическая политика',
            'foreign policy': 'Внешняя политика России',
            'nuclear policy': 'Ядерная политика',
            security: 'Безопасность',
            'recent articles & papers': 'Недавние исследования',
            'recently published': 'Недавние публикации',
            'Recently published': 'Недавние публикации',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'Spotify',
            applePodcasts: 'подкасты Apple',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: 'музыку Amazon',
            googlePodcasts: 'подкасты Google',
            youtubeMusic: 'YouTube Music',
            newsletters: 'Информационные рассылки',
            left: 'Налево',
            right: 'Направо',
            'link copied': 'ссылка скопирована',
            'Carnegie Experts': 'Эксперты Карнеги',
            'programs and projects': 'programs and projects',
            conference: 'Конференция',
            'register to attend': 'Зарегистрироваться',
            'add to calendar': 'Добавить в календарь',
            clear: 'прозрачный',
            german: 'немецкий',
            sourceGetty: 'Источник: Getty',
            copyright: '2024 Все права защищены.',
            'learn more': 'Читать',
            'Arms Control': 'контроль над вооружениями',
            'Artificial Intelligence': 'Искусственный интеллект',
            Biotechnology: 'Биотехнология',
            'Civil Society': 'Гражданское общество',
            'Climate Change': 'Climate Change',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'Демократия',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Внутренняя политика',
            EU: 'EU',
            Economy: 'Экономика',
            Education: 'Образование',
            Energy: 'Энергетика',
            'Foreign Policy': 'Внешняя политика России',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': 'Атомная энергетика',
            'Nuclear Policy': 'Ядерная политика',
            'Political Economy': 'Political Economy',
            'Political Reform': 'Политические реформы',
            Religion: 'Religion',
            Security: 'Безопасность',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'Всемирная торговля',
            Africa: 'Африка',
            'North Africa': 'Северная Африка',
            Algeria: 'Алжир',
            Egypt: 'Египет',
            Libya: 'Ливия',
            Mali: 'Мали',
            Morocco: 'Марокко',
            Sudan: 'Судан',
            Tunisia: 'Тунис',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: 'Мавритания',
            Americas: 'Americas',
            'North America': 'Северная Америка',
            'United States': 'Соединённые Штаты Америки',
            Canada: 'Канада',
            California: 'California',
            'Central America': 'Central America',
            Mexico: 'Мексика',
            'South America': 'South America',
            Asia: 'Asia',
            Kazakhstan: 'Казахстан',
            'Kyrgyz Republic': 'Кыргызстан',
            Tajikistan: 'Таджикистан',
            Turkmenistan: 'Туркменистан',
            Uzbekistan: 'Узбекистан',
            Russia: 'Россия',
            'East Asia': 'Восточная Азия',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: 'Япония',
            'North Korea': 'Корея, северная',
            'South Korea': 'Корея, южная',
            Taiwan: 'Тайвань',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': 'Южная Азия',
            Afghanistan: 'Афганистан',
            Pakistan: 'Пакистан',
            'Southeast Asia': 'Юго-Восточная Азия',
            Indonesia: 'Индонезия',
            Myanmar: 'Мьянма',
            Philippines: 'Филиппины',
            Vietnam: 'Вьетнам',
            Australia: 'Австралия',
            'Eastern Europe': 'Восточная Европа',
            Belarus: 'Белоруссия',
            Germany: 'Германия',
            Türkiye: 'Турция',
            Moldova: 'Молдавия',
            'Western Europe': 'Западная Европа',
            France: 'Франция',
            'United Kingdom': 'Соединённое Королевство',
            Bahrain: 'Бахрейн',
            Georgia: 'Грузия',
            Iran: 'Иран',
            Iraq: 'Ирак',
            Israel: 'Израиль',
            Jordan: 'Иордания',
            Kuwait: 'Кувейт',
            Lebanon: 'Ливан',
            Palestine: 'Палестина',
            Qatar: 'Катар',
            'Saudi Arabia': 'Саудовская Аравия',
            Syria: 'Сирия',
            'United Arab Emirates': 'Объединённые Арабские Эмираты (ОАЭ)',
            Yemen: 'Йемен',
            'Russia and Eurasia': 'Россия и Евразия',
            Caucasus: 'Кавказ',
            Armenia: 'Армения',
            Azerbaijan: 'Азербайджан',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Фонд Карнеги за Международный Мир как организация не выступает с общей позицией по общественно-политическим вопросам. В публикации отражены личные взгляды автора, которые не должны рассматриваться как точка зрения Фонда Карнеги за Международный Мир.',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'You will receive an email confirming your registration.':
                'Мы отправим подтверждение на вашу электронную почту.',
            'thank you': 'Спасибо!',
            Registration: 'Регистрация',
            Notes: 'Примечания',
        },
        hi: {
            'Carnegie Endowment for International Peace': 'कार्नेगी एन्डाउमन्ट फॉर इंटरनेशनल पीस',
            'Washington DC, USA': 'वॉशिंगटन,डीसी,अमेरिका',
            'California, USA': 'कैलिफ़ोर्निया,अमेरिका',
            Singapore: 'सिंगापुर',
            'Beijing, China': 'बीजिंग चाइना',
            'Brussels, Belgium': 'ब्रुसेल्स, बेल्जियम',
            'New Delhi, India': 'नई दिल्ली, भारत',
            'Beirut, Lebanon': 'बेरूत, लेबनान',
            'Berlin, Germany': 'बर्लिन, जर्मनी',
            'Activities in Singapore and Beijing, China': 'Activities in Singapore and Beijing, China',
            Global: 'वैश्विक',
            China: 'चीन',
            Europe: 'यूरोप',
            India: 'इंडिया',
            'Middle East': 'मध्य पूर्व',
            'Carnegie Europe': 'कार्नेगी यूरोप',
            'Carnegie India': 'कार्नेगी इंडिया',
            'Malcolm H. Kerr Carnegie Middle East Center': 'मैल्कम एच केर कार्नेगी मिडिल ईस्ट सेंटर',
            'Carnegie Russia Eurasia Center': 'कार्नेगी रूस यूरेशिया सेंटर',
            'Russia Eurasia': 'रूस यूरेशिया',
            'Carnegie China': 'कार्नेगी चीन',
            subscribe: 'सब्सक्राइब',
            Phone: 'टेलीफोन',
            'for media': 'मीडिया के लिए',
            'government resources': 'Government Resources',
            by: 'द्वारा',
            privacy: 'गोपनीयता कथन',
            country: 'देश',
            countries: 'देशों',
            'published by': 'द्वारा प्रकाशित',
            'Published on': 'पर प्रकाशित',
            'published on': 'पर प्रकाशित',
            'read more': 'और पढ़ें',
            'read less': 'कम पढ़ें',
            affiliations: 'संबद्धीकरण',
            and: 'और',
            'areas of expertise': 'विशेषज्ञता के क्षेत्र',
            education: 'शिक्षा',
            languages: 'बोली',
            english: 'अंग्रेज़ी',
            'featured work': 'विशेष रुप से प्रदर्शित काम',
            'featured collections': 'विशेष रुप से प्रदर्शित',
            Collections: 'विशेष रुप से प्रदर्शित',
            program: 'कार्यक्रम',
            blog: 'ब्लॉग',
            project: 'परियोजना',
            projects: 'परियोजनाओं',
            Projects: 'परियोजनाओं',
            results: 'परिणाम',
            Results: 'परिणाम',
            'see results': 'परिणाम देखें',
            'table of contents': 'विषयसूची',
            'about the carnegie endowment': 'बंदोबस्ती के बारे में',
            'about the center': 'केंद्र के बारे में',
            'about us': 'हमारे बारे में',
            'about the expert': 'जीवनी',
            'more on': 'अधिक जानकारी के लिए',
            'more on the center': 'केंद्र के बारे में',
            'trending topics and regions': 'ट्रेंडिंग विषय और क्षेत्र',
            'see all regions': 'सभी क्षेत्रों को देखें',
            'see all topics': 'सभी विषय देखें',
            'see the collection': 'संग्रह',
            'All work from': 'अधिक जानकारी के लिए',
            'who we are': 'who we are',
            commentary: 'विवरण',
            Commentary: 'विवरण',
            book: 'किताब',
            report: 'प्रतिवेदनt',
            'press release': 'प्रेस विज्ञप्ति',
            'q&a': 'प्रश्नोत्तर',
            'Q&A': 'प्रश्नोत्तर',
            testimony: 'गवाही',
            'scholar spotlight': 'विशेषज्ञ',
            video: 'वीडियो',
            Videos: 'वीडियो',
            videos: 'वीडियो',
            collection: 'संग्रह',
            'event collection': 'आयोजन संग्रह',
            edition: 'संस्करण',
            paper: 'लेखr',
            article: 'लेख',
            'in the media': 'मीडिया में',
            'In the Media': 'मीडिया में',
            january: 'जनवरी',
            february: 'फ़रवरी',
            march: 'मार्च',
            april: 'अप्रैल',
            may: 'मई',
            june: 'जून',
            july: 'जुलाई',
            august: 'अगस्त',
            september: 'सितम्बर',
            october: 'अक्टूबर',
            november: 'नवंबर',
            december: 'दिसंबर',
            January: 'जनवरी',
            February: 'फ़रवरी',
            March: 'मार्च',
            April: 'अप्रैल',
            May: 'मई',
            June: 'जून',
            July: 'जुलाई',
            August: 'अगस्त',
            September: 'सितम्बर',
            October: 'अक्टूबर',
            November: 'नवंबर',
            December: 'दिसंबर',
            event: 'आयोजन',
            episode: 'एपिसोड',
            'Podcast Episodes': 'एपिसोड',
            issue: 'मुद्दा',
            pdf: 'पीडीएफ़',
            'latest work': 'नवीनतम काम',
            filters: 'फिल्टर',
            topics: 'विषय',
            regions: 'क्षेत्र',
            Topics: 'विषय',
            Regions: 'क्षेत्र',
            types: 'प्रकार',
            years: 'साल',
            email: 'ईमेल',
            authors: 'लेखक',
            'additional links': 'अधिक लिंक',
            'event speakers': 'वक्ता',
            'Ep.': 'एपिसोड',
            'see podcast': 'पॉडकास्ट सुनें',
            'latest from': 'से नवीनतम',
            'hosted by': 'द्वारा आयोजित',
            featuring: 'की विशेषता',
            'subscribe on': 'सब्सक्राइब',
            'sign up for events': 'आयोजन की जानकारी के लिए साइन अप करें',
            'past events': 'पहले के आयोजनाएं',
            all: 'समस्त',
            All: 'समस्त',
            AllResearch: 'समस्त',
            'Center Affiliation': 'केंद्र संबद्धता',
            research: 'अनुसंधान',
            Research: 'अनुसंधान',
            'the emissary': 'the emissary',
            about: 'हमारा परिचय',
            experts: 'विशेषज्ञों',
            staff: 'कर्मचारी',
            donate: 'दान करें',
            Donate: 'दान करें',
            archive: 'archive',
            'this person has been archived': 'इस व्यक्ति को संग्रहीत किया गया है',
            'this project has been archived': 'इस परियोजना को संग्रहीत किया गया है।',
            previous: 'previous',
            Prev: 'previous',
            next: 'next',
            Next: 'next',
            with: 'with',
            more: 'अधिक',
            politika: 'politika',
            diwan: 'diwan',
            events: 'आयोजन',
            Events: 'आयोजन',
            programs: 'कार्यक्रमों',
            series: 'शृंखला',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                'यह विशेषज्ञ अब कार्नेगी एन्डाउमन्ट फॉर इंटरनेशनल पीस में एक सक्रिय विशेषज्ञ नहीं है',
            blogs: 'ब्लॉग',
            podcasts: 'पॉडकास्ट',
            Podcasts: 'पॉडकास्ट',
            'regional centers': 'क्षेत्रीय केंद्र',
            'about the program': 'प्रोग्राम के बारे में',
            'about the project': 'परियोजना के बारे में',
            'About the Project': 'परियोजना के बारे में',
            contact: 'संपर्क',
            'annual reports': 'annual reports',
            careers: 'करियर',
            'topics & regions': 'विषय और क्षेत्र',
            'Get more news and insights from': 'समाचार और अंतर्दृष्टि प्राप्त करें',
            'More work from': 'से अधिक काम',
            close: 'बंद करना',
            'recent articles & papers': 'हाल के लेख और कागजात',
            'recently published': 'हाल ही में प्रकाशित',
            'Recently published': 'हाल ही में प्रकाशित',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'स्पॉटिफाई',
            applePodcasts: 'एप्प्ल पॉडकास्ट',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: 'एमाज़ॉन म्यूजिक',
            googlePodcasts: 'गूगल पॉडकास्ट',
            youtubeMusic: 'YouTube Music',
            newsletters: 'समाचार',
            left: 'बाएं',
            right: 'दाहिनी',
            'link copied': 'लिंक कॉपी किया गया',
            'Carnegie Experts': 'कार्नेगी विशेषज्ञ',
            'programs and projects': 'कार्यक्रम और परियोजनाएँ',
            conference: 'सम्मेलन',
            'register to attend': 'उपस्थित होने के लिए रजिस्टर करें',
            'add to calendar': 'कैलेंडर में शामिल करें',
            clear: 'स्पष्ट',
            sourceGetty: 'स्रोत: गेटी',
            copyright: '2024 सर्वाधिकार सुरक्षित',
            'learn more': 'और अधिक जानें',
            'Arms Control': 'Arms Control',
            'Artificial Intelligence': 'Artificial Intelligence',
            Biotechnology: 'Biotechnology',
            'Civil Society': 'Civil Society',
            'Climate Change': 'Climate Change',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'Democracy',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Domestic Politics',
            EU: 'EU',
            Economy: 'Economy',
            Education: 'शिक्षा',
            Energy: 'Energy',
            'Foreign Policy': 'Foreign Policy',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': 'Nuclear Energy',
            'Nuclear Policy': 'Nuclear Policy',
            'Political Economy': 'राजनीतिक अर्थव्यवस्था',
            'Political Reform': 'Political Reform',
            Religion: 'Religion',
            Security: 'Security',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'Trade',
            Africa: 'Africa',
            'North Africa': 'North Africa',
            Algeria: 'Algeria',
            Egypt: 'Egypt',
            Libya: 'Libya',
            Mali: 'Mali',
            Morocco: 'Morocco',
            Sudan: 'Sudan',
            Tunisia: 'Tunisia',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: 'Mauritania',
            Americas: 'Americas',
            'North America': 'उत्तरी अमेरिका',
            'United States': 'अमेरिका',
            Canada: 'Canada',
            California: 'California',
            'Central America': 'entral America',
            Mexico: 'Mexico',
            'South America': 'South America',
            Asia: 'Asia',
            'Central Asia': 'Central Asia',
            Kazakhstan: 'Kazakhstan',
            'Kyrgyz Republic': 'Kyrgyz Republic',
            Tajikistan: 'Tajikistan',
            Turkmenistan: 'Turkmenistan',
            Uzbekistan: 'Uzbekistan',
            Russia: 'Russia',
            'East Asia': 'पूर्व एशिया',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: 'Japan',
            'North Korea': 'North Korea',
            'South Korea': 'South Korea',
            Taiwan: 'Taiwan',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': 'South Asia',
            Afghanistan: 'Afghanistan',
            Pakistan: 'Pakistan',
            'Southeast Asia': 'Southeast Asia',
            Indonesia: 'Indonesia',
            Myanmar: 'Myanmar',
            Philippines: 'Philippines',
            Vietnam: 'Vietnam',
            Australia: 'Australia',
            'Eastern Europe': 'Eastern Europe',
            Belarus: 'Belarus',
            Germany: 'Germany',
            Ukraine: 'Ukraine',
            Türkiye: 'Türkiye',
            Moldova: 'Moldova',
            'Western Europe': 'Western Europe',
            France: 'France',
            'United Kingdom': 'United Kingdom',
            Bahrain: 'Bahrain',
            Georgia: 'Georgia',
            Iran: 'Iran',
            Iraq: 'Iraq',
            Israel: 'Israel',
            Jordan: 'Jordan',
            Kuwait: 'Kuwait',
            Lebanon: 'Lebanon',
            Palestine: 'Palestine',
            Qatar: 'Qatar',
            'Saudi Arabia': 'Saudi Arabia',
            Syria: 'Syria',
            'United Arab Emirates': 'United Arab Emirates',
            Yemen: 'Yemen',
            'Russia and Eurasia': 'Russia and Eurasia',
            Caucasus: 'Caucasus',
            Armenia: 'Armenia',
            Azerbaijan: 'Azerbaijan',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'You will receive an email confirming your registration.':
                'You will receive an email confirming your registration.',
            'thank you': 'Thank you!',
            Registration: 'Registration',
            Notes: 'नोट्स',
        },
        es: {
            'Carnegie Endowment for International Peace': 'Carnegie Endowment for International Peace',
            'Washington DC, USA': 'Washington DC, USA',
            'California, USA': 'California, USA',
            Singapore: 'Singapore',
            'Beijing, China': 'Beijing, China',
            'Brussels, Belgium': 'Brussels, Belgium',
            'New Delhi, India': 'New Delhi, India',
            'Beirut, Lebanon': 'Beirut, Lebanon',
            'Berlin, Germany': 'Berlin, Germany',
            'Activities in Singapore and Beijing, China': 'Activities in Singapore and Beijing, China',
            Global: 'Global',
            China: 'China',
            Europe: 'Europe',
            India: 'India',
            'Middle East': 'Middle East',
            'Carnegie Europe': 'Carnegie Europe',
            'Carnegie India': 'Carnegie India',
            'Malcolm H. Kerr Carnegie Middle East Center': 'Malcolm H. Kerr Carnegie Middle East Center',
            'Carnegie Russia Eurasia Center': 'Carnegie Russia Eurasia Center',
            'Russia Eurasia': 'Russia Eurasia',
            'Carnegie China': 'Carnegie China',
            'Global Locations': 'Global Locations',
            'Annual Reports': 'Annual Reports',
            emissary: 'emissary',
            privacy: 'privacy',
            subscribe: 'subscribe',
            Phone: 'Phone',
            'for media': 'For Media',
            'government resources': 'Government Resources',
            by: 'by',
            country: 'country',
            countries: 'countries',
            'published by': 'published by',
            'Published on': 'Published on',
            'published on': 'published on',
            'read more': 'read more',
            'read less': 'read less',
            affiliations: 'afiliaciones',
            and: 'and',
            'areas of expertise': 'areas of expertise',
            education: 'educación',
            languages: 'idiomas',
            english: 'english',
            'featured work': 'featured work',
            'featured collections': 'featured collections',
            Collections: 'Collections',
            program: 'program',
            blog: 'blog',
            project: 'project',
            projects: 'projects',
            results: 'results',
            Results: 'Results',
            'see results': 'see results',
            'table of contents': 'table of contents',
            'about the carnegie endowment': 'about the carnegie endowment',
            'about the center': 'about the center',
            'about us': 'about',
            'about the expert': 'sobre',
            'more on': 'more on',
            'more on the center': 'more on the center',
            'trending topics and regions': 'trending topics and regions',
            'see all regions': 'see all regions',
            'see all topics': 'see all topics',
            'see the collection': 'see the collection',
            'All work from': 'Todo el trabajo de',
            'who we are': 'who we are',
            commentary: 'commentary',
            book: 'book',
            report: 'report',
            'press release': 'press release',
            'q&a': 'q&a',
            'Q&A': 'Q&A',
            testimony: 'testimony',
            'scholar spotlight': 'scholar spotlight',
            video: 'video',
            collection: 'collection',
            'event collection': 'event collection',
            edition: 'edition',
            paper: 'paper',
            article: 'article',
            'in the media': 'in the media',
            'In the Media': 'In the Media',
            'In The Media': 'In The Media',
            january: 'january',
            february: 'february',
            march: 'march',
            april: 'april',
            may: 'may',
            june: 'june',
            july: 'july',
            august: 'august',
            september: 'september',
            october: 'october',
            november: 'november',
            december: 'december',
            January: 'january',
            February: 'february',
            March: 'march',
            April: 'april',
            May: 'may',
            June: 'june',
            July: 'july',
            August: 'august',
            September: 'september',
            October: 'october',
            November: 'november',
            December: 'december',
            event: 'event',
            episode: 'episode',
            issue: 'issue',
            pdf: 'pdf',
            'latest work': 'latest work',
            filters: 'filters',
            topics: 'topics',
            regions: 'regions',
            Topics: 'Topics',
            Regions: 'Regions',
            types: 'types',
            years: 'years',
            email: 'email',
            editions: 'editions',
            authors: 'authors',
            'additional links': 'additional links',
            'event speakers': 'event speakers',
            podcast: 'podcast',
            'Ep.': 'Ep.',
            'see podcast': 'see podcast',
            'latest from': 'latest from',
            'hosted by': 'Hosted By',
            featuring: 'Featuring',
            'subscribe on': 'Subscribe On',
            'sign up for events': 'Sign up for Events',
            'past events': 'Past Events',
            all: 'All',
            All: 'All',
            AllResearch: 'All',
            'Center Affiliation': 'Center Affiliation',
            research: 'research',
            'the emissary': 'the emissary',
            about: 'sobre',
            experts: 'experts',
            staff: 'staff',
            donate: 'donate',
            Donate: 'Donate',
            archive: 'archive',
            'this person has been archived': 'this person has been archived',
            'this project has been archived': 'this project has been archived',
            previous: 'previous',
            next: 'next',
            with: 'with',
            more: 'more',
            politika: 'politika',
            diwan: 'diwan',
            events: 'events',
            programs: 'programs',
            series: 'series',
            'this expert is no longer an active Scholar at Carnegie Endowment for International Peace':
                'this expert is no longer an active Scholar at Carnegie Endowment for International Peace', //needs translation
            blogs: 'blogs',
            videos: 'videos',
            podcasts: 'podcasts',
            'regional centers': 'regional centers',
            'about the program': 'sobre el programa',
            'About the Program': 'Sobre el programa',
            'about the project': 'about the project',
            'About the Project': 'About the project',
            'Program experts': 'Expertos del programa',
            contact: 'contact',
            'annual reports': 'annual reports',
            careers: 'careers',
            'topics & regions': 'Topics & Regions',
            'Get more news and insights from': 'Get more news and analysis from',
            'More work from': 'More work from',
            close: 'Close',
            'recent articles & papers': 'Recent Articles & Papers',
            'recently published': 'recently published',
            'Recently published': 'recently published',
            facebook: 'Facebook',
            twitter: 'Twitter',
            spotify: 'Spotify',
            applePodcasts: 'Apple Podcasts',
            overcast: 'Overcast',
            stitcher: 'Stitcher',
            playerFM: 'Player FM',
            amazonMusic: 'Amazon Music',
            googlePodcasts: 'Google Podcasts',
            youtubeMusic: 'YouTube Music',
            newsletters: 'newsletters',
            left: 'Left',
            right: 'Right',
            'link copied': 'Link copied',
            'Carnegie Experts': 'Carnegie Experts',
            'programs and projects': 'programs and projects',
            conference: 'conference',
            'register to attend': 'register to attend',
            'add to calendar': 'add to calendar',
            clear: 'clear',
            russian: 'russian',
            german: 'german',
            sourceGetty: 'Source: Getty',
            copyright: '2024 Carnegie Endowment for International Peace. All rights reserved.',
            'learn more': 'Learn More',
            'Arms Control': 'Arms Control',
            'Artificial Intelligence': 'Artificial Intelligence',
            Biotechnology: 'Biotechnology',
            'Civil Society': 'Civil Society',
            'Climate Change': 'Climate Change',
            Corruption: 'Corruption',
            Cybersecurity: 'Cybersecurity',
            Democracy: 'Democracy',
            Disinformation: 'Disinformation',
            'Domestic Politics': 'Domestic Politics',
            EU: 'EU',
            Economy: 'Economy',
            Education: 'Educación',
            Energy: 'Energy',
            'Foreign Policy': 'Foreign Policy',
            'Global Governance': 'Global Governance',
            Migration: 'Migration',
            Military: 'Military',
            NATO: 'NATO',
            'Nuclear Energy': 'Nuclear Energy',
            'Nuclear Policy': 'Nuclear Policy',
            'Political Economy': 'Political Economy',
            'Political Reform': 'Political Reform',
            Religion: 'Religion',
            Security: 'Security',
            'Society and Culture': 'Society and Culture',
            Space: 'Space',
            Technology: 'Technology',
            Trade: 'Trade',
            Africa: 'Africa',
            'North Africa': 'North Africa',
            Algeria: 'Algeria',
            Egypt: 'Egypt',
            Libya: 'Libya',
            Mali: 'Mali',
            Morocco: 'Morocco',
            Sudan: 'Sudan',
            Tunisia: 'Tunisia',
            'Southern, Eastern, and Western Africa': 'Southern, Eastern, and Western Africa',
            Mauritania: 'Mauritania',
            Americas: 'Americas',
            'North America': 'North America',
            'United States': 'United States',
            Canada: 'Canada',
            California: 'California',
            'Central America': 'entral America',
            Mexico: 'Mexico',
            'South America': 'South America',
            Asia: 'Asia',
            'Central Asia': 'Central Asia',
            Kazakhstan: 'Kazakhstan',
            'Kyrgyz Republic': 'Kyrgyz Republic',
            Tajikistan: 'Tajikistan',
            Turkmenistan: 'Turkmenistan',
            Uzbekistan: 'Uzbekistan',
            Russia: 'Russia',
            'East Asia': 'East Asia',
            'Indo-Pacific': 'Indo-Pacific',
            Japan: 'Japan',
            'North Korea': 'North Korea',
            'South Korea': 'South Korea',
            Taiwan: 'Taiwan',
            'Indian Ocean': 'Indian Ocean',
            'South Asia': 'South Asia',
            Afghanistan: 'Afghanistan',
            Pakistan: 'Pakistan',
            'Southeast Asia': 'Southeast Asia',
            Indonesia: 'Indonesia',
            Myanmar: 'Myanmar',
            Philippines: 'Philippines',
            Vietnam: 'Vietnam',
            Australia: 'Australia',
            'Eastern Europe': 'Eastern Europe',
            Belarus: 'Belarus',
            Germany: 'Germany',
            Ukraine: 'Ukraine',
            Türkiye: 'Türkiye',
            Moldova: 'Moldova',
            'Western Europe': 'Western Europe',
            France: 'France',
            'United Kingdom': 'United Kingdom',
            Bahrain: 'Bahrain',
            Georgia: 'Georgia',
            Iran: 'Iran',
            Iraq: 'Iraq',
            Israel: 'Israel',
            Jordan: 'Jordan',
            Kuwait: 'Kuwait',
            Lebanon: 'Lebanon',
            Palestine: 'Palestine',
            Qatar: 'Qatar',
            'Saudi Arabia': 'Saudi Arabia',
            Syria: 'Syria',
            'United Arab Emirates': 'United Arab Emirates',
            Yemen: 'Yemen',
            'Russia and Eurasia': 'Russia and Eurasia',
            Caucasus: 'Caucasus',
            Armenia: 'Armenia',
            Azerbaijan: 'Azerbaijan',
            support: 'support',
            Newsletters: 'Newsletters',
            ' government resources': ' government resources',
            'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.',
            'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie, its staff, or its trustees.':
                'Carnegie India does not take institutional positions on public policy issues; the views represented herein are those of the author(s) and do not necessarily reflect the views of Carnegie India, its staff, or its trustees.',
            'You will receive an email confirming your registration.':
                'You will receive an email confirming your registration.',
            'thank you': 'Thank you!',
            Registration: 'Registration',
            Notes: 'Notas',
        },
    },
}));
